'use client';

import { useRef, useState } from 'react';

import classNames from 'classnames';

import DashedBorder from '@uikit/components/DashedBorder/DashedBorder';
import UploadCloud from '@uikit/icons/UploadCloud';

export type FileDropzoneProps = {
  disabled?: boolean;
  isError?: boolean;
  className?: string;
  id?: string;
  name?: string;
  onClick?: () => void;
  onBlur?: () => void;
  onChange?: (files: FileList | null) => void;
  maxSize?: number;
  accept?: string;
  multiple?: boolean;
};

type FileDropzoneState = 'default' | 'active' | 'error' | 'disabled';

const getState = ({
  isError,
  disabled,
  isDragActive,
  isFocused,
}: Pick<FileDropzoneProps, 'isError' | 'disabled'> & {
  isFocused: boolean;
  isDragActive: boolean;
}): FileDropzoneState => {
  if (disabled) {
    return 'disabled';
  } else if (isError) {
    return 'error';
  } else if (isDragActive || isFocused) {
    return 'active';
  }

  return 'default';
};

const FileDropzone = ({
  id,
  className,
  isError,
  disabled,
  name,
  onBlur,
  onChange,
  onClick,
  maxSize,
  accept,
  multiple = false,
}: FileDropzoneProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragActive(false);

    const files = event.dataTransfer.files;

    if (onChange && files) {
      onChange(files);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (onChange && files) {
      onChange(files);
    }
  };

  const state = getState({ isError, disabled, isDragActive, isFocused });

  return (
    <div
      className={classNames(
        'relative flex min-h-[150px] select-none flex-col items-center justify-center overflow-hidden rounded-lg p-6',
        'focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-primary',
        'group',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className,
      )}
      onDragEnter={handleDragEnter}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);

        if (onBlur) {
          onBlur();
        }
      }}
      onClick={onClick}
    >
      <DashedBorder
        borderRadius={8}
        strokeWidth={1}
        strokeDasharray="4, 4"
        strokeLinecap="round"
        strokeDashoffset={6}
        className={classNames(
          'pointer-events-none absolute inset-0 transition-colors',
          {
            active: 'bg-primary-50 text-primary',
            disabled: 'text-neutral-100',
            default: 'text-primary-300 group-hover:text-primary',
            error: 'text-red-600',
          }[state],
        )}
      />
      {!disabled && (
        <input
          ref={inputRef}
          type="file"
          id={id}
          name={name}
          className="absolute w-[100%] h-[100%] z-10 opacity-0"
          onChange={handleInputChange}
          multiple={multiple}
          accept={accept}
          onBlur={() => {
            setIsFocused(false);

            if (onBlur) {
              onBlur();
            }
          }}
          {...(maxSize && {
            maxLength: maxSize,
          })}
        />
      )}
      <label className="flex flex-col items-center" htmlFor={id}>
        <UploadCloud
          className={classNames(
            'relative icon-xl mb-2',
            {
              disabled: 'text-neutral-300',
              error: 'text-red-600',
              default: 'text-neutral-600',
              active: 'text-neutral-600',
            }[state],
          )}
        />
        <p
          className={classNames(
            'relative flex flex-col gap-1 text-center text-base font-medium leading-5',
            disabled ? 'text-neutral-300' : 'text-neutral-400',
          )}
        >
          Drag files to upload
          <span className={classNames({ 'text-primary': !disabled })}>
            Choose a file or drag it here.
          </span>
        </p>
      </label>
    </div>
  );
};

export default FileDropzone;
