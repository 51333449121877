'use client';

import { ComponentPropsWithoutRef, useEffect } from 'react';

import { useAccordion } from '@uikit/components/Accordion/Accordion';
import Collapse from '@uikit/components/Collapse/Collapse';

export interface AccordionDetailsProps extends ComponentPropsWithoutRef<'div'> {
  onOpen?: (value: boolean) => void;
}

export default function AccordionDetails({ onOpen, ...props }: AccordionDetailsProps) {
  const { expanded } = useAccordion();

  useEffect(() => {
    if (onOpen) {
      onOpen(expanded);
    }
  }, [onOpen, expanded]);

  return <Collapse isOpen={expanded} {...props} />;
}
